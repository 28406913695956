/* 系统菜单系统名样式 */
.menu-title {
  height: 48px;
  color: #333333;
  font-weight: bold;
  font-size: 17px;
  font-family: 'PingFangSC-Semibold, "PingFang SC Semibold", "PingFang SC", sans-serif';
  font-style: normal;
}
/* 系统菜单系统名样式 */
.menu-title > p {
  padding: 10px 0 0 24px;
}
.devops-slider-cls {
  height: 100vh;
  background-color: #f5f5f5 !important;
}
.devops-slider-menus-cls {
  background-color: #f5f5f5 !important;
}
.devops-slider-menus-cls .ant-layout-sider-children {
  background-color: #f5f5f5 !important;
}
/* 系统菜单样式 */
.system-menu {
  width: 208px;
  font-weight: 400;
  font-size: 14px;
  background-color: #f5f5f5 !important;
  /* 一级菜单空div隐藏 */
  /* 覆盖antd选择菜单自带a链接样式 */
  /* 设置选择菜单items时的样式 */
}
.system-menu > li > div:empty {
  display: none;
}
.system-menu a:hover {
  color: #1890ff;
}
.system-menu > li > div:hover,
.system-menu .system-menu-non-child:hover,
.system-menu .system-menu-inner:hover {
  color: #1890ff;
  background-color: #f5f5f5 !important;
}
.system-menu .ant-menu-item-only-child,
.system-menu .ant-menu-submenu {
  border-top: 1px solid #e2e0e0;
}
.system-menu .ant-menu-submenu .ant-menu-item-only-child {
  border-top: none;
}
.system-menu .ant-menu-item {
  margin: 0 !important;
  padding: 0 !important;
  background-color: #f5f5f5 !important;
}
.system-menu .ant-menu-item .system-menu-non-child {
  padding-left: 24px;
}
.system-menu .ant-menu-item .system-menu-inner {
  font-size: 13px;
  padding-left: 48px;
}
.system-menu .ant-menu-item-selected {
  background-color: #f5f5f5 !important;
}

